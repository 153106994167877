import React, { useState } from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from "../layouts"
import Seo from "../components/seo"
import {
	BreadCrumb,
	SectionPageTitle,
	Section,
	SectionTitle,
	SectionDescription,
  BaseButton,
} from "../components/Section"
import GrayWoodSection from "../components/Section/GrayWoodSection";
import Content, { HTMLContent } from '../components/Content';
import ProductCard from "../components/ProductCard";
import RoofStyle from "../components/RoofStyle";
import generateHTML from '../utils/generateHTML';
import AvailableSection from "../sections/Category/AvailableSection";
import CtaSection from '../sections/CtaSection';

const HeroImagesWrapeer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	margin: 64px auto -150px;

	@media(max-width:768px) {
		grid-template-columns: repeat(1, 1fr);
		margin-bottom: 50px;
	}
`
const HeroImage = styled.div`
	width: 100%;
	height: 100%;
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
	@media(max-width:768px) {
		height: 300px;
	}
`

const ProductListWrap = styled.div`
	max-width: 1100px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
`
const LoadMoreButton = styled(BaseButton)`
  display: block;
  width: fit-content;
  background: #D51333;
  color:#fff;
  border-radius: 6px;
  box-shadow: 0 6px 0 #982221;
  font-family: "Oswald", sans-serif;
  margin: auto;
  
  &:after {		
    background: #A20000;
  }
  &:hover {
    cursor: pointer;
  }
`

const SubCategoryLandingTemplate = ({ data, location }) => {
  const [showLimit, setShowLimit] = useState(10);
	const pageData = data.contentfulSubCategoryLanding;
	const PostContent = HTMLContent || Content;

	let currentSubCategoryProduct = []
	data.allContentfulProduct.edges.forEach(function(item) {
		if (item.node.subCategory?.name === pageData.subCategory.name) {
			currentSubCategoryProduct.push(item.node);
		}
	})
	const availableSectionData = {
		title: pageData.availableSectionTitle,
		description: pageData.availableSectionDescription,
		sizes: pageData.availableSizes
	}
	return (
		<Layout footerCta location={location}>
			<Seo title={pageData.metaTitle} description={pageData.metaDescription} />
			<GrayWoodSection pt="0">
				<BreadCrumb position="relative">
	        <div className="container">
	          <Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
	          <Link to={pageData.subCategory.category.url}>{pageData.subCategory.category.name}</Link><span>&nbsp;/&nbsp;</span>
	          <span>{pageData.subCategory.name}</span>
	        </div>
	      </BreadCrumb>
      </GrayWoodSection>
      <GrayWoodSection pt="0">
      	<div className="container">
      		<SectionPageTitle>{pageData.heroTitle}</SectionPageTitle>
      		<SectionDescription maxWidth="950px">
						<div dangerouslySetInnerHTML={{__html: pageData.heroDescription.childMarkdownRemark.html}} />
					</SectionDescription>
			{ pageData.subCategory.category.name!=="Steel Buildings" && pageData.subCategory.category.name!=="Portable Buildings" && (
				<HeroImagesWrapeer>
					<HeroImage>
						<GatsbyImage image={getImage(pageData.heroImages[0].gatsbyImageData)} alt="Buildings And More" />
					</HeroImage>
					<HeroImage>
						<GatsbyImage image={getImage(pageData.heroImages[1].gatsbyImageData)} alt="Buildings And More" />
					</HeroImage>
				</HeroImagesWrapeer>
			)}	
      	</div>
      </GrayWoodSection>
      <Section spt="50px" mpt="150px" pt={pageData.subCategory.category.name!=="Steel Buildings" && pageData.subCategory.category.name!=="Portable Buildings" ? "200px": "100px"}>
        <div className="container">
          <SectionTitle mb="40px">{pageData.productSectionTitle}</SectionTitle>
          <ProductListWrap>
            {currentSubCategoryProduct.map((product, i) => i < showLimit && (
                <ProductCard data={product} key={`product-${i}`} />
              )
            )}
            {currentSubCategoryProduct.length > showLimit && (
              <LoadMoreButton onClick={()=>setShowLimit(showLimit + 10)}>Load More Buildings</LoadMoreButton>
            )}
          </ProductListWrap>
        </div>
      </Section>

		{pageData.availableSectionDescription && (
			<AvailableSection data={availableSectionData} />
		)}
		{pageData.subCategory.category.name !== "Portable Buildings" && (
			<RoofStyle />
		)}
		{pageData.subCategory.category.name !== "Portable Buildings" && (
			<CtaSection mb="0" />
		)}
		{pageData.content && (
			<GrayWoodSection maxWidth="900px" mb="100px">
				<div className="container">
					<PostContent	content={generateHTML(pageData.content.childMarkdownRemark.html)} />
				</div>
			</GrayWoodSection>
		)}
	</Layout>
	)
}

export default SubCategoryLandingTemplate;

export const pageQuery = graphql`
  query SubCategoryLandingTemplate($id: String!) {
    contentfulSubCategoryLanding(id: { eq: $id }) {
      metaTitle
      metaDescription
      subCategory {
      	name
      	
      	category {
      		name
      		url
      	}
      }
      heroTitle
	    heroDescription {
	      childMarkdownRemark {
	    		html
	    	}
	    }
	    heroImages {
        gatsbyImageData(placeholder: BLURRED, quality: 90)
      }
	    productSectionTitle
	    availableSectionTitle
	    availableSectionDescription {
	      childMarkdownRemark {
	        html
	      }
	    }
	    availableSizes {
	      content
	    }
	    content {
	    	childMarkdownRemark {
	    		html
	    	}
	    }
    }
	  allContentfulProduct {
      edges {
        node {
          category {
            name
          }
          subCategory {
          	name
          }
          name
          sku
          width
          length
          height
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 50)
          }
          startingPrice
          downPayment
          url
        }
      }
    }
  }
`